<template>
  <div>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header d-flex justify-content-between flex-lg-row flex-column align-items-lg-center'>
            <vb-headers-card-header :data="{ title: 'Filtre&nbsp;Seçiniz' }" />

            <div class='d-flex flex-lg-row flex-column ml-2'>
              <a-select :default-value='premiumFilterState' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 230px' @change='premiumFilterStateChange'>
                <a-select-option value='ALL'>
                  Tüm Hesaplar
                </a-select-option>
                <a-select-option value='ONLY_PREMIUMS'>
                  Premium Hesaplar
                </a-select-option>
                <a-select-option value='ONLY_NON_PREMIUMS'>
                  Premium Olmayan Hesaplar
                </a-select-option>
              </a-select>
              <a-input-search v-model:value='searchText' placeholder='Arama Yapın...' @search='loadCustomers' enter-button='Ara' />
            </div>
          </div>

          <div class='card-header d-flex justify-content-between flex-lg-row flex-column align-items-lg-center'>
            <vb-headers-card-header :data="{ title: 'Kullanıcı&nbsp;Seçiniz' }" />

            <div class='d-flex flex-lg-row flex-column ml-2'>
              <a-select :default-value='filterType' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 200px;' @change='filterTypeChange'>
                <a-select-option value='SELECTED_USERS'>
                  Sadece Seçilen Kullanıcılar
                </a-select-option>
                <a-select-option value='FILTERED_USERS'>
                  Filtre Sonucundaki Herkes
                </a-select-option>
              </a-select>

              <a-button v-if='filterType === "SELECTED_USERS"' class='btn btn-warning mr-lg-2 mb-lg-0 mb-2' @click='selectAllCustomers'>Tümünü Seç</a-button>
              <a-button v-if='filterType === "SELECTED_USERS"' class='btn btn-danger' :disabled='selectedCustomers.length < 1' @click='() => selectedCustomers = []'>
                Seçimleri Kaldır
                <span
                  v-if='selectedCustomers.length > 0'>{{ `&nbsp;(${selectedCustomers.length})` }}
                </span>
              </a-button>
            </div>
          </div>
          <div class='card-body'>
            <div>
              <div class='table-responsive text-nowrap'>
                <a-table :loading='loading'
                         :columns='columns'
                         :data-source='customers'
                         :pagination='false'
                         :row-key='(record) => record.id'
                         :locale='{emptyText: "Kullanıcı Bulunamadı"}'
                         :custom-row='customRow'
                >
                  <template #id='{ record }'>
                    <span class='font-weight-bold'>{{ record.id }}</span>
                  </template>
                  <template #fullName='{ record }'>
                    <div class='vb__utils__avatar' style='width: 2.5rem' @click.stop>
                      <a-image
                        :src='record.avatar.avatar4X'
                        alt='User'
                        fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                      />
                    </div>
                    <span class='ml-2'>{{ record.fullName }}</span>
                  </template>
                  <template #premium='{ record }'>
                    <span :class='record.premium ? "font-weight-bold" : ""'>{{ record.premium === true ? 'Premium' : 'Premium Değil' }}</span>
                  </template>
                  <template #referencedPersonalTrainer='{ record }'>
                    <div class='vb__utils__avatar' style='width: 2.5rem' @click.stop>
                      <a-image
                        :src='record.referencedPersonalTrainer ? record.referencedPersonalTrainer.avatar.avatar4X : " "'
                        alt='User'
                        fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                      />
                    </div>
                    <span class='ml-2'
                          :class='!record.referencedPersonalTrainer ? "font-italic text-gray-3" : ""'>
                      {{ record.referencedPersonalTrainer ? record.referencedPersonalTrainer.name + ' ' + record.referencedPersonalTrainer.surname : 'Referans Yok' }}
                    </span>
                  </template>
                  <template #email='{ record }'>
                    <span>{{ record.email }}</span>
                  </template>
                  <template #operation='{record}'>
                    <a-checkbox :disabled='filterType !== "SELECTED_USERS"' :checked='!!selectedCustomers.find(x => x.id === record.id)'></a-checkbox>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <div class='card-footer'>
            <div class='clearfix'>
              <a-pagination
                class='float-right'
                @change='loadCustomers'
                v-model:page-size='pagination.recordsPerPage'
                :default-page-size='pagination.recordsPerPage'
                v-model:current='pagination.currentPage'
                :default-current='pagination.currentPage'
                :total='pagination.totalRecords'
              />
            </div>
          </div>
        </div>
      </div>

      <div class='col-lg-12 col-md-12'>
        <send-notification-and-push-notification-widget
          :selected-customers='selectedCustomers'
          :filter-premium-type='premiumFilterState'
          :filter-search-text='searchText'
          :send-to='filterType'
        />
      </div>

    </div>
  </div>
</template>

<script>
import SendNotificationAndPushNotificationWidget from './sendNotificationAndPushNotificationWidget'
import { CustomerAdministration } from '../../services/api-service'

export default {
  name: 'VbBildirimler',
  components: {
    SendNotificationAndPushNotificationWidget,
  },
  data() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        slots: { customRender: 'id' },
      },
      {
        title: 'Kullanıcı Bilgisi',
        class: 'd-flex align-items-center',
        dataIndex: 'fullName',
        slots: { customRender: 'fullName' },
      },
      {
        title: 'Hesap Durumu',
        dataIndex: 'premium',
        slots: { customRender: 'premium' },
      },
      {
        title: 'Kişisel Antrenör',
        class: 'd-flex align-items-center',
        dataIndex: 'referencedPersonalTrainer',
        slots: { customRender: 'referencedPersonalTrainer' },
      },
      {
        title: 'Mail Adresi',
        dataIndex: 'email',
        slots: { customRender: 'email' },
      },
      {
        title: 'Seçim',
        dataIndex: 'selection',
        class: 'text-right',
        slots: { customRender: 'operation' },
      },
    ]

    return {
      columns,
      loading: false,
      customers: [],
      selectedCustomers: [],
      orderByJoinCountOfBroadcasts: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      searchText: '',
      // 'ALL' | 'ONLY_PREMIUMS' | 'ONLY_NON_PREMIUMS'
      premiumFilterState: 'ALL',
      // "FILTERED_USERS" | "SELECTED_USERS"
      filterType: 'SELECTED_USERS',
    }
  },
  mounted() {
    this.loadCustomers()
  },
  methods: {
    async loadCustomers() {
      this.loading = true
      try {
        const { data } = await CustomerAdministration.listCustomersForAdministration(this.orderByJoinCountOfBroadcasts, this.pagination.currentPage, this.premiumFilterState, this.searchText)
        this.customers = data.customers
        this.pagination = data.pagination
      } catch {
      }
      this.loading = false
    },
    clickCustomer(customer) {
      const findCustomer = this.selectedCustomers.find(x => x.id === customer.id)
      if (typeof findCustomer !== 'undefined') {
        this.deleteCustomer(customer)
      } else {
        this.addCustomer(customer)
      }
    },
    addCustomer(customer) {
      if (!this.selectedCustomers.includes(customer)) this.selectedCustomers.push(customer)
    },
    deleteCustomer(customer) {
      this.selectedCustomers = this.selectedCustomers.filter(x => x.id !== customer.id)
    },
    selectAllCustomers() {
      this.selectedCustomers = [...this.selectedCustomers, ...this.customers.filter(x => !this.selectedCustomers.includes(x))]
    },
    premiumFilterStateChange(value) {
      if (this.premiumFilterState !== value) {
        this.premiumFilterState = value
      }
    },
    filterTypeChange(value) {
      if (this.filterType !== value) {
        this.selectedCustomers = []
        this.filterType = value
      }
    },
    customRow(record) {
      return {
        onClick: () => {
          if (this.filterType === 'SELECTED_USERS') {
            this.clickCustomer(record)
          }
        },
      }
    },
  },
}
</script>
