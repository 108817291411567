<template>
  <div class='card card-top card-top-primary'>
    <div class='card-header py-0'>
      <div class='card-header-flex'>
        <div class='d-flex flex-column justify-content-center mr-auto'>
          <div class='d-flex flex-row justify-content-center'>
            <h5 class='mb-0 mr-2'>
              <i class='fe fe-smartphone mr-2 font-size-18 text-muted' />
              <strong>Bildirim Gönder</strong>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class='card-body'>

      <a-form :model='formState' label-align='left' layout='vertical'>
        <a-form-item label='Bildirim Başlığı'>
          <a-input v-model:value='formState.title' placeholder='Bildirim Başlığı' />
        </a-form-item>

        <a-form-item label='Bildirim Açıklaması'>
          <a-textarea
            v-model:value='formState.description'
            placeholder='Bildirim İçeriği'
          />
        </a-form-item>

        <a-form-item label='Bildirim Gönderim Tipi'>
          <a-select v-model:value='formState.sendType' class='mr-lg-2 mb-lg-0 mb-2 w-100' style='min-width: 230px'>
            <a-select-option value='BOTH'>
              Telefona ve Uygulama İçi Bildirim
            </a-select-option>
            <a-select-option value='ONLY_IN_APP_NOTIFICATION'>
              Sadece Uygulama İçi Bildirim
            </a-select-option>
            <a-select-option value='ONLY_PUSH_NOTIFICATION'>
              Sadece Telefona Bildirim
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label='Şu Kullanıcılara Gönder'>
          <div style='border: 1px solid #eee; border-radius: 8px;padding:4px 11px;max-height: 300px; overflow-y: auto'>
            <div v-if='sendTo === "FILTERED_USERS"'>
              {{ `${filterPremiumType === 'ALL' ? 'Tüm Hesaplar' : filterPremiumType === 'ONLY_PREMIUMS' ? 'Premium Hesaplar' : 'Premium Olmayan Hesaplar'}${filterSearchText !== '' ? ' arasında "' + filterSearchText + '" içeren hesaplar' : ''}` }}
            </div>
            <div v-if='selectedCustomers.length < 1 && sendTo === "SELECTED_USERS"'>
              Herkes
            </div>
            <template v-for='(customer, i) in selectedCustomers' :key='(i + 9).toString(36) + i'>
              <div style='display: flex;align-items: center;margin:3px 0'>
                <a-image
                  style='width: 30px;height: 30px;border-radius:30px;margin-right:10px'
                  :src='customer.avatar?.avatar4X ?? " "'
                  alt='User'
                  fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                />
                <div>
                  {{ customer.fullName }}
                </div>
              </div>
            </template>
          </div>
          <div v-if='selectedCustomers.length > 0' style='margin-top: 5px;'>
            Toplam {{ selectedCustomers.length }} Kişiye Gönderilecek
          </div>
        </a-form-item>

        <a-form-item>
          <a-button @click='onSubmit' class='btn btn-success' :loading='loading'>
            Bildirimi Gönder
          </a-button>
        </a-form-item>
      </a-form>

    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRaw, watch } from 'vue'
import { NotificationAdministration, OneSignalAdministration } from '../../services/api-service'
import { notification } from 'ant-design-vue'

export default defineComponent({
  name: 'SendNotificationAndPushNotificationWidget',
  props: {
    selectedCustomers: {
      type: Array,
      default: () => [],
    },
    filterPremiumType: {
      type: String,
      default: 'ALL',
    },
    filterSearchText: {
      type: String,
      default: '',
    },
    sendTo: {
      type: String,
      default: 'SELECTED_USERS',
    },
  },
  setup() {
    const formState = reactive({
      // title: string,
      // description: string,
      // sendType: 'ONLY_PUSH_NOTIFICATION' | 'ONLY_IN_APP_NOTIFICATION' | 'BOTH',
      // users?: number[], // Bunun içine userAccountId'ler gelecek
      // sendTo: 'SELECTED_USERS' | 'FILTERED_USERS',
      // filterSearchText?: string,
      // filterPremiumType?: 'ALL' | 'ONLY_PREMIUMS' | 'ONLY_NON_PREMIUMS'
      users: [],
      title: '',
      description: '',
      sendType: 'BOTH',
      sendTo: 'SELECTED_USERS',
      filterSearchText: '',
      filterPremiumType: 'ALL',
    })

    return {
      formState,
    }
  },
  data() {
    watch(() => this.selectedCustomers, (customers) => {
      console.log(customers)
      if (customers.length > 0) {
        this.formState.users = customers.map(({ customerMetadataId }) => customerMetadataId)
      } else {
        this.formState.users = []
      }
    })

    watch(() => this.filterPremiumType, (type) => {
      this.formState.filterPremiumType = type
    })

    watch(() => this.filterSearchText, (text) => {
      this.formState.filterSearchText = text
    })

    watch(() => this.sendTo, (type) => {
      this.formState.sendTo = type
    })

    return {
      loading: false,
      customers: [],
      toCustomers: undefined,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true

      try {
        await NotificationAdministration.sendNotificationToUsers({
          users: this.selectedCustomers.map(({ customerMetadataId }) => customerMetadataId),
          description: this.formState.description,
          filterPremiumType: this.formState.filterPremiumType,
          filterSearchText: this.formState.filterSearchText,
          sendTo: this.formState.sendTo,
          sendType: this.formState.sendType,
          title: this.formState.title,
        })
        notification.success({
          message: 'Bildirim Başarıyla Gönderildi!',
        })
      } catch {
      }

      this.loading = false
    },
  },
})
</script>
